import React, {Component, Fragment} from 'react'
import {flowRight as compose, find, first, get, map, size} from 'lodash'
import {Trans, withNamespaces} from 'react-i18next'
import messages from '../../assets/messages'
import {CustomDialog} from '../Common/Dialog'
import withStyles from '@mui/styles/withStyles'
import {withRouter} from 'react-router-dom'
import AppContext from '../Common/contexts/AppContext'
import {graphql} from 'react-apollo'
import {ACCOUNTS_QUERY} from '../../graphql/queries'
import {hasIntroducingBrokerAccount, isIntroducingBrokerAccount, isMobile, logEventCustomParams} from '../../common/utils'
import {FormControl, Grid, InputBase, MenuItem, Select, SvgIcon, Tooltip, Typography} from '@mui/material'
import {ReactComponent as CopyIcon} from '../../assets/images/copyLink.svg'
import CopyToClipboard from 'react-copy-to-clipboard'
import classNames from 'classnames'
import {t} from 'i18next/dist/commonjs'

const styles = theme => ({
  partnerId: {
    fontWeight: 400,
    fontSize: '16px',
    letterSpacing: '0.5px',
    lineHeight: '24px',
  },
  closeIcon: {
    position: 'absolute',
    top: 24,
    [theme.direction === 'rtl' ? 'left' : 'right']: 24,
    color: theme.palette.grey[400],
    cursor: 'pointer',
  },
  linkBox: {
    display: 'flex',
    fontSize: '12px',
    gap: '16px',
    width: isMobile() ? '293px' : '492px',
    height: isMobile() ? '72px' : '56px',
    borderRadius: '6px',
    border: theme.palette.mode === 'dark' ? '1px solid var(--stroke-1, #1F1F1F)' : '1px solid var(--stroke-1, #EBEBEB)',
    padding: '12px 16px 12px 16px',
    backgroundColor: theme.palette.extralightgrey.color,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  linksBox: {
    width: isMobile() ? '295px' : '492px',
    height: isMobile() ? '112px' : '96px',
    gap: '10px',
    borderRadius: '6px',
    border: theme.palette.mode === 'dark' ? '1px solid var(--stroke-1, #1F1F1F)' : '1px solid var(--stroke-1, #EBEBEB)',
    padding: isMobile() ? ' 12px 16px 12px 16px' : '12px 16px 12px 16px',
    backgroundColor: theme.palette.extralightgrey.color,
    justifyContent: 'space-between'
  },
  dialogTitle: {
    textAlign: 'start',
  },
  link: {
    fontWeight: '400px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    wordBreak: 'break-all'
  },
  pointer: {
    cursor: 'pointer',
    textAlign: 'right',
  },
  linkCopy: {
    color: theme.palette.primary.main,
    fontWeight: '500',
    display: 'flex',
    alignItems: 'center',
  },
  multipleLinks: {
    fontWeight: 400,
    fontSize: '12px',
    letterSpacing: '0.4px',
    lineHeight: '16px',
    wordBreak: 'break-all'
  },
  box: {
    paddingBottom: '14px',
    display: 'grid',
    padding: '0px 14px 0px 14px',
  },
})

const BootstrapInput = withStyles((theme) => ({
  input: {
    borderRadius: 4,
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.lightgrey.color}`,
    fontSize: 14,
    padding: '4px 4px 4px 8px',
    margin: 0,
    color: `${theme.palette.black.color}`,
  },
}))(InputBase)

class PartnerLinksDialog extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      open: true,
      onClose: true,
      data: null,
      clickedEvent: false,
      displaySelectedLink: false,
      selectedValue: '',
      defaultLink: '',
    }
  }
  componentDidMount() {
    logEventCustomParams('partnerLinksModalPresented', {
      userId: get(this.props, 'viewer.id'),
    })
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {accounts} = nextProps

    if (!prevState.selectedValue && accounts) {
      const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
      const partnerDetails = get(ibAccount, 'partnerDetails', [])
      if (partnerDetails.length > 0) {
        return {
          selectedValue: partnerDetails[0].ibId,
          defaultLink: partnerDetails[0].clientCampaignUrl,
        }
      }
    }
    return null
  }

  copyLink() {
    this.setState({clickedEvent: true})
  }

  handleChangeId = (event) => {
    const {accounts} = this.props
    const selectedValue = event.target.value
    const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
    const partnerDetails = get(ibAccount, 'partnerDetails', [])
    const selectedLink = partnerDetails.find(detail => detail.ibId === selectedValue)?.clientCampaignUrl || ''

    this.setState({
      selectedValue,
      defaultLink: selectedLink,
    })
  }

  renderContent() {
    const {accounts, classes} = this.props
    const {clickedEvent, selectedValue, defaultLink} = this.state
    const ibAccount = hasIntroducingBrokerAccount(accounts) && find(accounts, (__typename) => isIntroducingBrokerAccount(__typename))
    const singleClientLink = size(get(ibAccount, 'partnerDetails')) === 1
    const multipleClientLink = size(get(ibAccount, 'partnerDetails')) > 1
    const partnerDetails = get(ibAccount, 'partnerDetails')
    const singleLink = first(partnerDetails).clientCampaignUrl
    const ibIds = map(partnerDetails, (m) => m.ibId)
    return (
      <Fragment>
        <Grid container>
          <Grid item className={isMobile() ? classes.box : ''}>
            {multipleClientLink && (
              <Grid className={classes.linksBox}>
                <Grid display={'flex'} gap={isMobile() ? '16px' : '95px'}>
                  <Grid>
                    <Grid display={'flex'} gap={'12px'} alignItems={'center'} paddingBottom={'8px'}>
                      <Typography variant="body1" className={classes.partnerId}>
                        <Trans {...messages.partnerId} />:
                      </Typography>
                      <Grid>
                        <FormControl style={{boarderRadius: '4px'}}>
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={selectedValue}
                            onChange={this.handleChangeId}
                            input={<BootstrapInput />}
                          >
                            {ibIds.map((option, index) => (
                              <MenuItem key={index} value={option}>
                                {option}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid display={'flex'} width={isMobile() ? '224px' : '336px'} height={isMobile() ? '48px' : '32px'}>
                      <Typography variant="caption" className={classes.multipleLinks}>
                        {defaultLink}
                      </Typography>
                    </Grid>
                  </Grid>
                  <CopyToClipboard text={singleLink} onCopy={(result) => {
                    if (result) {
                      logEventCustomParams('partnerLinkCopyCompleted',{
                        userId:  get(this.props, 'viewer.id')
                      })
                    } else {
                      logEventCustomParams('partnerLinkCopyRequestFailed',{
                        userId:  get(this.props, 'viewer.id')
                      })
                    }
                  }}>
                    <Typography
                      variant="caption"
                      className={classNames(classes.linkCopy, classes.pointer)}
                      onClick={() => {
                        this.copyLink()
                        logEventCustomParams('partnerLinkCopyAttempted',{
                          userId:  get(this.props, 'viewer.id')
                        })
                      }}
                      onMouseLeave={() => this.setState({clickedEvent: false})}
                    >
                      <SvgIcon>
                        <CopyIcon />
                      </SvgIcon>
                    </Typography>
                  </CopyToClipboard>
                  <Tooltip
                    open={clickedEvent}
                    title={t(messages.partnerLinkCopied.i18nKey, messages.partnerLinkCopied.defaults)}
                    placement="right"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    onClose={() => this.setState({clickedEvent: false})}
                  >
                    <span></span>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
            {singleClientLink && (
              <Grid className={classes.linkBox}>
                <Grid width={'365px'}>
                  <Typography variant="caption" className={classes.link}>
                    {singleLink}
                  </Typography>
                </Grid>
                <Grid>
                  <CopyToClipboard text={singleLink}
                    onCopy={(result) => {
                      if (result) {
                        logEventCustomParams('partnerLinkCopyCompleted',{
                          userId:  get(this.props, 'viewer.id')
                        })
                      } else {
                        logEventCustomParams('partnerLinkCopyRequestFailed',{
                          userId:  get(this.props, 'viewer.id')
                        })
                      }
                    }}>
                    <Typography
                      variant="caption"
                      className={classNames(classes.linkCopy, classes.pointer)}
                      onClick={() => {
                        this.copyLink()
                        logEventCustomParams('partnerLinkCopyAttempted',{
                          userId:  get(this.props, 'viewer.id')
                        })
                      }}
                      onMouseLeave={() => this.setState({clickedEvent: false})}
                    >
                      <SvgIcon viewBox='0 0 24 24'>
                        <CopyIcon />
                      </SvgIcon>
                    </Typography>
                  </CopyToClipboard>
                  <Tooltip
                    open={clickedEvent}
                    title={t(messages.partnerLinkCopied.i18nKey, messages.partnerLinkCopied.defaults)}
                    placement="right"
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    onClose={() => this.setState({clickedEvent: false})}
                  >
                    <span></span>
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  render() {
    const {open, onClose, classes} = this.props

    return (
      <React.Fragment>
        <CustomDialog
          open={open}
          onClose={onClose}
          closeBtn={true}
          onCloseIcon={onClose}
          customClasses={{
            closeIcon: classes.closeIcon,
            dialogTitle: classes.dialogTitle,
          }}
          title={<Trans {...messages.introduceClientLink} />}
        >
          {this.renderContent()}
        </CustomDialog>
      </React.Fragment>
    )
  }
}

export default compose(
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    props: ({data: {error, loading}, data}) => ({
      error,
      loading,
      accounts: get(data, 'viewer.accounts'),
    }),
  })
)(PartnerLinksDialog)
