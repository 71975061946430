import PropTypes from 'prop-types'
import React from 'react'
import Box from '@mui/material/Box'
import {Trans, withNamespaces} from 'react-i18next'
import Typography from '@mui/material/Typography'

import {AlertDialog} from '../Common/Dialog'
import messages from '../../assets/messages'
import withStyles from '@mui/styles/withStyles'

const styles = (theme) => ({
  agreeButton: {
    '&.Mui-disabled': {
      backgroundColor: '#85C0FF',
      color: '#fff',
    }
  },
  dialogTitle: {
    textAlign: 'left',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(3),
    }
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    padding: `0 ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
})

const RequestPartnerAccountPopup = ({open, onAgree, onClose, classes, loading, callbackRequested, t}) => (
  <AlertDialog
    open={open}
    onClose={onClose}
    onAgree={onAgree}
    disagreeText={t(messages.cancel.i18nKey, messages.cancel.defaults)}
    agreeText={callbackRequested
      ? t(messages.callbackRequested.i18nKey, messages.callbackRequested.defaults)
      : t(messages.requestCallback.i18nKey, messages.requestCallback.defaults)
    }
    customClasses={{
      agreeButton: classes.agreeButton,
      dialogTitle: classes.dialogTitle,
      dialogContent: classes.dialogContent,
      dialogActions: classes.dialogActions,
    }}
    title={t(messages.openPartnerAccountPopupTitle.i18nKey, messages.openPartnerAccountPopupTitle.defaults)}
    buttonDisabled={callbackRequested}
    hideProgressBar={!loading}
    dialogProps={{sx: {zIndex: 'snackbar'}}}
    closeBtn
  >
    <Box display='flex' flexDirection='column' gap='16px'>
      <Box>
        <Typography variant='body1'>
          <Trans {...messages.openPartnerAccountPopupContent} />
        </Typography>
      </Box>
    </Box>
  </AlertDialog>
)


RequestPartnerAccountPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  callbackRequested: PropTypes.bool.isRequired,
}

export default withStyles(styles, {withTheme: true})(withNamespaces()(RequestPartnerAccountPopup))
