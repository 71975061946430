import React, {Component} from 'react'
import {withApollo, graphql} from 'react-apollo'
import {LinearProgress} from '@mui/material'
import {Redirect} from 'react-router-dom'
import {get, filter, isEmpty, orderBy, find, capitalize, flowRight as compose, includes, map, last} from 'lodash'
import {logoutRequest, setAuthToken} from '../../common/utils/requests'
import {
  removeSessInfoFromLocalStorage,
  storeItem,
  setCookie,
  getCookie,
  isMobile,
  getUrlParam
} from '../../common/utils'
import {CLIENT_DATA_QUERY, ACCOUNTS_QUERY} from '../../graphql/queries'
import {BugsnagErrorBoundary} from '../../bugsnag'
import SocketManager from '../../socketManager'
import ErrorPage from '../Common/ErrorPage'
import AppContext from '../Common/contexts/AppContext'
import {getItem} from '../../common/utils/localStorage'
import Desktop from './Desktop'
import Mobile from './Mobile'
import i18nApp from '../../i18n'
import {
  hasOnlyDepositBlockedAccounts,
  hasIntroducingBrokerAccount,
  isIntroducingBrokerAccount,
  isAffiliatesAccount,
  hasAffiliateAccount,
  isIBClient,
  isLdClientWalletAccount,
} from '../../common/utils/accounts'
import {
  accountTypes,
  clientNoticeTypes,
  companies,
  countries,
  dueDiligenceStatuses,
  kycStatuses,
  languages,
} from '@bdswiss/common-enums'
import config from '../../config'
import {findCompany, getCurrentTheme, getLocaleMoment, getMissingVerifications} from '../../common/utils/general'
import {AlertDialog} from '../Common/Dialog'
import messages from '../../assets/messages'
import Typography from '@mui/material/Typography'
import {Trans, withNamespaces} from 'react-i18next'
import moment from 'moment'
import queryString from 'qs'
import {firebaseInit, logEventCustomParams} from '../../common/utils/firebaseEvents'
import AffiliateAgreementPopup from '../Accounts/AffiliateAgreementPopup'
import Loading from '../Common/Loading'
import SystemNotification from '../Common/SystemNotification'
import RequestPartnerAccountPopup from '../Accounts/RequestPartnerAccountPopup'
import {REQUEST_OPEN_PARTNER_ACCOUNT_MUTATION} from '../../graphql/mutations'

class App extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.showRequestPartnerWalletPopup = this.showRequestPartnerWalletPopup.bind(this)
    this.hideRequestPartnerWalletPopup = this.hideRequestPartnerWalletPopup.bind(this)
  }

  state = {
    document: '',
    notification: null,
    openPopup: false,
    openTemporaryCeasingPopup: true,
    openRequestPartnerWalletPopup: false,
    activityDetectorTimeout: this.activityDetectorTimeout.bind(this),
    systemNotificationType: '',
    systemNotificationMessage: '',
    systemNotificationCustomMessage: 'false',
  }

  async componentDidMount() {

    await this.checkTokenCrmAuthRedirect()

    if (config.gTagContainerId) {
      //tagmanager body noscript
      const gtmExternal = document.createElement('noscript')
      const gtmIframe = document.createElement('iframe')
      gtmIframe.src = `https://www.googletagmanager.com/ns.html?id=${config.gTagContainerId}`
      gtmIframe.height = '0'
      gtmIframe.width = '0'
      gtmIframe.setAttribute('style', 'display:none;visibility:hidden')
      gtmExternal.appendChild(gtmIframe)

      //tagmanager head script
      const gtmElement = document.createElement('script')
      gtmElement.id = 'gtm-script'
      gtmElement.type = 'text/javascript'
      gtmElement.text = `
			(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
			new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
			j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
			'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
			})(window,document,'script','dataLayer','${config.gTagContainerId}');`
      document.getElementsByTagName('head').item(0).appendChild(gtmElement)
      document.getElementsByTagName('body').item(0).appendChild(gtmExternal)
    }

    // if (config.googleMapKey) {
    if (false) {
      //googleMap script
      const googleMapElement = document.createElement('script')
      googleMapElement.id = 'gmap-script'
      googleMapElement.type = 'text/javascript'
      googleMapElement.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapKey}&libraries=places`
      document.getElementsByTagName('head').item(0).appendChild(googleMapElement)
    }

    const startFirbase = firebaseInit()
    startFirbase && logEventCustomParams('initial_check_in')

    window.addEventListener('mousedown', this.state.activityDetectorTimeout, true)
    window.addEventListener('touchstart', this.state.activityDetectorTimeout, true)
  }

  componentWillUnmount() {
    window.removeEventListener('mousedown', this.state.activityDetectorTimeout, true)
    window.removeEventListener('touchstart', this.state.activityDetectorTimeout, true)
  }

  activityDetectorTimeout() {
    const activityDetector = window.localStorage.getItem('activity_detector')
    const inTimeFrame = moment().isBefore(
      moment(activityDetector).add(config.common.activityDetector.timeframe, 'minutes'),
    )
    if (isEmpty(activityDetector) || inTimeFrame) {
      window.localStorage.setItem('activity_detector', new Date().toISOString())
    } else {
      this.handleLogout()
    }
  }

  beforeSendErrorReport(report) {
    const {viewer} = this.props
    const clientId = get(viewer, 'id')
    report.user = {id: clientId}
  }

  handleLogout = forceDocument => {
    const {client: apolloClient} = this.props
    if (forceDocument) this.setState({openPopup: true, forceDocument})
    else
      logoutRequest()
        .then(() => {
          setCookie('missing_approp_test', false, -1)
          setCookie('missing_economic_profile', false, -1)
          setCookie('approp_test_notification', false, -1)
          setCookie('missing_profile', false, -1)
          setCookie('show_copyTrading_promo', false, -1)
          window.localStorage.removeItem('activity_detector')
          removeSessInfoFromLocalStorage()
          window.liveChat && window.LC_API?.hide_chat_window()
          this.setState({loggedOut: true})
          SocketManager.deinitialize()
          apolloClient && apolloClient.clearStore()
        })
        .catch(console.error) // eslint-disable-line no-console
  }

  showRequestPartnerWalletPopup() {
    this.setState({openRequestPartnerWalletPopup: true})
  }

  hideRequestPartnerWalletPopup() {
    this.setState({openRequestPartnerWalletPopup: false})
  }

  checkDownloadEbook(ebookRedirect, euMigrationRedirect) {
    if (euMigrationRedirect) return
    const {location} = this.props
    if (ebookRedirect && location.pathname !== '/tools/ebook') {
      return <Redirect to={{pathname: '/tools/ebook', state: {from: location, locationTrack: 'Registration'}}} />
    }
  }

  checkEmailVerification(ebookRedirect, euMigrationRedirect) {
    if (euMigrationRedirect) return
    const {location, viewer} = this.props
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const clientEmail = get(viewer, 'email')

    if (
      !emailConfirmed &&
      emailVerificationRequired &&
      location.pathname !== '/email-verification-needed' &&
      location.pathname.indexOf('/confirm-email') === -1
    )
      return <Redirect to={{pathname: '/email-verification-needed', state: {from: location, email: clientEmail}}} />
  }

  checkPhoneVerification(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')

    // Campaign related
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const emailVerificationDone =
      ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) &&
      location.pathname.indexOf('/confirm-email') === -1

    // Phone verification related
    const isEmailLoginVerified = get(viewer, 'isEmailLoginVerified')

    if (
      !isEmailLoginVerified &&
      isPhoneVerificationRequired &&
      !phoneVerified &&
      location.pathname !== '/verification' &&
      emailVerificationDone
    )
      return <Redirect to={{pathname: '/verification', state: {from: location}}} />
  }

  checkEmailLoginVerification(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer} = this.props

    // Campaign related
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const emailVerificationDone =
      ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) &&
      location.pathname.indexOf('/confirm-email') === -1

    // Email login verification related
    const isEmailLoginVerificationRequired = get(viewer, 'isEmailLoginVerificationRequired')
    const isEmailLoginVerified = get(viewer, 'isEmailLoginVerified')

    // Phone verification related
    const phoneVerified = get(viewer, 'isPhoneVerified')

    if (
      !phoneVerified &&
      isEmailLoginVerificationRequired &&
      !isEmailLoginVerified &&
      location.pathname !== '/verification' &&
      emailVerificationDone
    )
      return <Redirect to={{pathname: '/verification', state: {from: location}}} />
  }

  checkAppropTest(companyObject, verificationFields, ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return

    const {location, viewer} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const isEmailLoginVerificationRequired = get(viewer, 'isEmailLoginVerificationRequired')
    const isEmailLoginVerified = get(viewer, 'isEmailLoginVerified')
    const phoneEmailVerificationDone =
      (phoneVerified || isEmailLoginVerified || (!isPhoneVerificationRequired && !isEmailLoginVerificationRequired)) &&
      ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) &&
      location.pathname.indexOf('/confirm-email') === -1
    const termsUpdateSeen = (getItem('termsUpdate') && getItem('termsUpdateSeen')) || !getItem('termsUpdate')

    const forcedVerification = get(viewer, 'verificationActions.forcedVerification')
    const appropTest = get(viewer, 'appropTests')
    const globalQuestionnaire = get(viewer, 'globalQuestionnaire')

    const accountVerificationByEntity = companies[companyObject.key].accountVerification

    const forceAppropTest =
      forcedVerification === 'appropriatenessTest' && verificationFields.indexOf('appropriatenessTest') > 0
    const forceEconomicProfile =
      forcedVerification === 'economicProfile' && verificationFields.indexOf('economicProfile') > 0

    const exceptionPage = location.pathname === '/support'

    if (companies[companyObject.key].requiresAPTest && verificationFields.indexOf('appropriatenessTest') > 0) {
      if (isEmpty(appropTest) && (isEmpty(getCookie('missing_approp_test')) || forceAppropTest)) {
        setCookie('missing_approp_test', true, 1)
      } else {
        setCookie('missing_approp_test', false, 1)
      }
    }

    const showEconomicProfile =
      accountVerificationByEntity &&
      accountVerificationByEntity.economicProfile &&
      !isEmpty(accountVerificationByEntity.economicProfile)
    if (showEconomicProfile) {
      if (!globalQuestionnaire && (isEmpty(getCookie('missing_economic_profile')) || forceEconomicProfile)) {
        setCookie('missing_economic_profile', true, 1)
      } else {
        setCookie('missing_economic_profile', false, 1)
      }
    }

    const {accountVerification, cysecRegulator} = config
    const missingVerifications = getMissingVerifications(
      viewer,
      appropTest,
      globalQuestionnaire,
      '',
      accountVerification,
    )
    const forceProfileDetails = cysecRegulator && includes(missingVerifications, 'profile')
    if (forceProfileDetails) {
      setCookie('missing_profile', true, 1)
    }

    const appropTestCompleted =
      (!isEmpty(appropTest) && getCookie('approp_test_completed_step1') && getCookie('approp_test_completed')) ||
      (!isEmpty(appropTest) && !getCookie('approp_test_completed_step1'))

    const appTestPath = '/settings/profile/appropriateness-test'
    const economicPath = '/settings/profile/economic-profile'
    const personalDetailsPath = '/settings/profile/personal-profile/details'
    const appTestNotification = getCookie('approp_test_notification')
    if (
      !forceAppropTest &&
      !forceEconomicProfile &&
      appropTestCompleted &&
      forceProfileDetails &&
      location.pathname !== personalDetailsPath &&
      cysecRegulator
    ) {
      return <Redirect to={{pathname: personalDetailsPath, state: {force: forceProfileDetails}}} />
    }
    if (
      phoneEmailVerificationDone &&
      termsUpdateSeen &&
      location.pathname !== appTestPath &&
      forceAppropTest &&
      !exceptionPage
    ) {
      return <Redirect to={{pathname: appTestPath, state: {force: forceAppropTest}}} />
    } else if (
      phoneEmailVerificationDone &&
      location.pathname !== economicPath &&
      forceEconomicProfile &&
      !forceAppropTest &&
      appropTestCompleted &&
      !exceptionPage &&
      (!appTestNotification || (appTestNotification && location.pathname !== appTestPath))
    ) {
      setCookie('approp_test_completed', true, 1 / (24 * 60))
      setCookie('approp_test_completed_step1', true, 1 / (24 * 60))
      return <Redirect to={{pathname: economicPath, state: {force: forceEconomicProfile}}} />
    } else if (
      includes([appTestPath, economicPath], get(location, 'state.redirectTo')) &&
      get(location, 'state.prevPath') === '/login'
    ) {
      return <Redirect to={{pathname: '/dashboard'}} />
    }
  }

  checkSubscriptionRedirect(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer, accounts} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const forcedVerification = get(viewer, 'verificationActions.forcedVerification')
    const isEmailLoginVerificationRequired = get(viewer, 'isEmailLoginVerificationRequired')
    const isEmailLoginVerified = get(viewer, 'isEmailLoginVerified')
    const phoneEmailVerificationDone =
      (phoneVerified || isEmailLoginVerified || (!isPhoneVerificationRequired && !isEmailLoginVerificationRequired)) &&
      ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) &&
      location.pathname.indexOf('/confirm-email') === -1 &&
      !forcedVerification

    if (!getItem('subscription_redirect') && phoneEmailVerificationDone) {
      const rawAccount = filter(
        accounts,
        account => accountTypes[account['__typename']].subscription && isEmpty(account.subscription),
      )

      if (!isEmpty(rawAccount) && location.pathname.indexOf('plan') === -1) {
        storeItem('subscription_redirect', true)
        return <Redirect to={{pathname: `/dashboard/${rawAccount[0]['id']}/plan/payment`, state: {rawAccount}}} />
      }
    }
  }

  checkDueDiligenceRedirect(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer, accounts} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const forcedVerification = get(viewer, 'verificationActions.forcedVerification')
    const isEmailLoginVerificationRequired = get(viewer, 'isEmailLoginVerificationRequired')
    const isEmailLoginVerified = get(viewer, 'isEmailLoginVerified')
    const phoneEmailVerificationDone =
      (phoneVerified || isEmailLoginVerified || (!isPhoneVerificationRequired && !isEmailLoginVerificationRequired)) &&
      ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) &&
      location.pathname.indexOf('/confirm-email') === -1 &&
      !forcedVerification
    const dueDiligences = get(viewer, 'dueDiligences')
    const ibAccount =
      hasIntroducingBrokerAccount(accounts) && find(accounts, __typename => isIntroducingBrokerAccount(__typename))
    const ibApproved = get(ibAccount, 'approved', false)

    const affiliateAccount =
    hasAffiliateAccount(accounts) && find(accounts, __typename => isAffiliatesAccount(__typename))
    const dueDiligencePath = '/settings/profile/due-diligence'
    const dueDilligenceRequired =
      (config.common.dueDilligenceRequired.affiliate && affiliateAccount &&
        moment(get(affiliateAccount, 'createdAt')).isAfter(config.common.forceEddBasedOnDate)) ||
      (config.common.dueDilligenceRequired.ib && ibAccount && !ibApproved)
    // eslint-disable-next-line
    console.log('Redirection --->>', phoneEmailVerificationDone, dueDilligenceRequired, isEmpty(dueDiligences))
    if (phoneEmailVerificationDone && dueDilligenceRequired
      && isEmpty(dueDiligences) && location.pathname.indexOf(dueDiligencePath) === -1) {
      storeItem('duediligence_redirect', true)
      return <Redirect to={{pathname: dueDiligencePath, state: {force: isEmpty(dueDiligences)}}} />
    } else if (get(location, 'state.prevPath') === '/login' && get(location, 'state.redirectTo') === dueDiligencePath) {
      return <Redirect to={{pathname: '/dashboard'}} />
    }
  }

  checkClientNoticesRedirect(ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer} = this.props
    const phoneVerified = get(viewer, 'isPhoneVerified')
    const isPhoneVerificationRequired = get(viewer, 'isPhoneVerificationRequired')
    const emailVerificationRequired = get(viewer, 'isEmailVerificationRequired')
    const emailConfirmed = get(viewer, 'emailConfirmed')
    const isEmailLoginVerificationRequired = get(viewer, 'isEmailLoginVerificationRequired')
    const isEmailLoginVerified = get(viewer, 'isEmailLoginVerified')
    const phoneEmailVerificationDone =
      (phoneVerified || isEmailLoginVerified || (!isPhoneVerificationRequired && !isEmailLoginVerificationRequired)) &&
      ((emailConfirmed && emailVerificationRequired) || !emailVerificationRequired) &&
      location.pathname.indexOf('/confirm-email') === -1

    const pendingNotices = get(viewer, 'pendingNotices')
    const notice =
      pendingNotices &&
      orderBy(pendingNotices, n => n.id, ['desc']).filter(
        n =>
          n.type === clientNoticeTypes.termsChange.key ||
          n.type === clientNoticeTypes.partners.key ||
          n.type === clientNoticeTypes.migration.key ||
          (n.type === clientNoticeTypes.informative.key && isEmpty(n.acknowledgements)),
      )
    if (
      phoneEmailVerificationDone &&
      !isEmpty(pendingNotices) &&
      !isEmpty(notice) &&
      !getItem('termsUpdate') &&
      !isEmpty(notice[0].content)
    ) {
      storeItem('termsUpdate', true)
      return <Redirect to={{pathname: '/clientNotice', state: {notice: notice}}} />
    }
  }

  async checkTokenCrmAuthRedirect() {
    const tokenCrm = getUrlParam('tokenCrm')
    if (tokenCrm) await setAuthToken(tokenCrm)
  }

  checkEuMigration(euMigrateRedirect) {
    const {location} = this.props
    if (euMigrateRedirect && location.pathname !== '/dashboard' && location.pathname.indexOf('/support') === -1)
      return <Redirect to={{pathname: '/dashboard'}} />
  }

  checkParnerZone(clientType, ebookRedirect, euMigrationRedirect) {
    if (ebookRedirect || euMigrationRedirect) return
    const {location, viewer} = this.props
    const {showPartnerZone} = config
    const isIb = isIBClient(clientType)
    const dueDiligences = get(viewer, 'dueDiligences')
    const dueDiligence = last(orderBy(filter(dueDiligences, a => a.status !== dueDiligenceStatuses.rejected.value)))
    const partnerZone = get(viewer, 'kycStatus') === kycStatuses.approved.value && !isEmpty(dueDiligence)
    const conditions = isIb && partnerZone && showPartnerZone
    if (conditions && location.pathname !== '/partner-zone' && location.pathname.indexOf('/support') === -1) {
      return <Redirect to={{pathname: '/partner-zone'}} />
    } else {
      return <Redirect to={{pathname: '/dashboard'}} />
    }
  }

  checkCorporateRedirect(corporateRedirect) {
    const {location} = this.props
    if (corporateRedirect && location.pathname.indexOf('/corporate') === -1)
      return <Redirect to={{pathname: '/corporate'}} />
  }
  showSystemNotification = (systemNotificationType, systemNotificationMessage, systemNotificationCustomMessage) => {
    this.setState({
      systemNotificationType: systemNotificationType,
      systemNotificationMessage: systemNotificationMessage,
      systemNotificationCustomMessage: systemNotificationCustomMessage,
    })
  }
  hideSystemNotification () {
    this.setState({
      systemNotificationType: '',
      systemNotificationMessage: '',
      systemNotificationCustomMessage: false,
    })
  }

  showNotification = ({
    type,
    status,
    content,
    buttonMessage,
    onClose,
    hideButton,
    subTitle,
    buttonAction,
    linkAction,
    linkActionMessage,
    subTitleCenter,
    title,
    hideTitle,
    subTitleChildren,
  }) =>
    this.setState({
      notification: {
        type,
        status,
        content,
        buttonMessage,
        onClose,
        hideButton,
        subTitle,
        buttonAction,
        linkAction,
        linkActionMessage,
        subTitleCenter,
        title,
        hideTitle,
        subTitleChildren,
      },
    })

  render() {
    const {loggedOut, notification, openPopup, forceDocument, openTemporaryCeasingPopup,
      openRequestPartnerWalletPopup} = this.state
    const {
      loading, error, viewer, accounts, loadingAccounts, location, requestPartnerAccount, requestPartnerAccountResult,
      t, location: {state, search}, systemNotificationType, systemNotificationMessage,
      systemNotificationCustomMessage
    } = this.props
    const {
      key,
      productConfigs,
      accountVerification,
      temporaryCeasingPopup,
      euRegulation: {showTermsPopup},
      forcedCompany,
      showInformativePageOnly,
    } = config

    const affiliateAggreementUrls = get(viewer, 'affiliateAggreementUrls')
    const affiliateAggreementTemplate = get(viewer, 'affiliateAggreementTemplate')
    const showAffiliateAgreementPopup = get(viewer, 'affiliateAggreementStatus') === 'pending'
    const hasPartnerAccount = get(viewer, 'hasPartnerAccount')
    const openPartnerAccountRequested = get(viewer, 'openPartnerAccountRequested')

    if (getUrlParam('tokenCrm')) return <Loading />

    if (loggedOut) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {from: location, loggedOut},
          }}
        />
      )
    }

    if (loading || loadingAccounts) {
      document.body.classList.add('loadingApp')
      return <LinearProgress />
    } else {
      document.body.classList.remove('loadingApp')
    }

    if (accounts && isEmpty(accounts)) {
      logEventCustomParams('logout', {
        reason: 'noAccount',
        userId: get(viewer, 'id'),
      })
      this.handleLogout()
      return null
    }

    const viewerType = get(viewer, '__typename')

    if (error || (viewerType && viewerType !== 'Client')) {
      if (get(error, 'networkError.result.data.redirectUrl')) {
        const url = new URL(get(error, 'networkError.result.data.redirectUrl'))
        window.location = url.origin + location.pathname + url.search
        return null
      }
      return <Redirect to={{pathname: '/login', state: {from: location, isError: true}}} />
    }

    if (showInformativePageOnly && location.pathname.indexOf('informativePage') !== 1) {
      return <Redirect to={{pathname: '/informativePage'}} />
    }

    if (get(viewer, 'pendingLoginVerification') && location.pathname.indexOf('/choose-entity') === -1) {
      return <Redirect to={{pathname: '/login-verification'}} />
    }

    let locale = get(viewer, 'locale') || getItem('locale', 'en')

    if (
      !includes(
        map(
          filter(languages, lang => !lang.disabled && lang.client),
          'value',
        ),
        locale,
      )
    )
      locale = 'en'

    moment.locale(getLocaleMoment(locale))
    let themePreference = getCurrentTheme(this.context)
    const cookieDomain = window.location.hostname.match(/[^.]+\.\w+$/)
      ? window.location.hostname.match(/[^.]+\.\w+$/)[0]
      : window.location.hostname
    setCookie('theme', capitalize(themePreference), 5 / (24 * 60), cookieDomain)

    const tradingStatus = get(viewer, 'tradingStatus')
    const tradingStatusReasonCode = get(viewer, 'tradingStatusReasonCode')
    const blockedDeposit = hasOnlyDepositBlockedAccounts(filter(accounts, a => !isLdClientWalletAccount(a)))
    const clientType = get(viewer, 'clientType')

    let verificationFields
    if (blockedDeposit && clientType && productConfigs[clientType]) {
      verificationFields = productConfigs[clientType].accountVerification
    } else {
      verificationFields = accountVerification
    }

    if (locale !== getItem('locale')) {
      storeItem('locale', locale)
      i18nApp.changeLanguage(locale, this.context)
    }

    if (themePreference !== getItem('themePreference') && getItem('themePreference') !== getItem('pendingPreference')) {
      storeItem('themePreference', themePreference)
      this.context.toggleTheme(themePreference)
    } else {
      themePreference = getCurrentTheme()
      if (this.context.themePreference !== themePreference) {
        this.context.toggleTheme(themePreference)
      }
    }

    SocketManager.initialize(get(viewer, 'id'))

    const companyObject = findCompany(forcedCompany)
    const queryParams = get(state, 'queryParams') || queryString.parse(search.replace('?', ''))
    const ebookParam = get(queryParams, 'ebook')
    !isEmpty(ebookParam) && storeItem('ebook', ebookParam)
    const ebookRedirect = !isEmpty(getItem('ebook'))
    const corporateRedirect =
      get(viewer, 'corporateSignupStep') &&
      (!get(viewer, 'corporateStatus') || location.pathname === '/corporate/signing')

    const redirectCorporate = this.checkCorporateRedirect(corporateRedirect)
    if (redirectCorporate) return redirectCorporate

    if (get(queryParams, 'refEntity')) {
      storeItem('refEntity_redirect', get(location, 'search'))
    }

    const euMigrateRedirect = !!showTermsPopup && get(viewer, 'euMigration') && !get(viewer, 'acceptEuMigrationTerms')

    const euMigrationRedirect = !corporateRedirect && this.checkEuMigration(euMigrateRedirect)
    if (euMigrationRedirect) return euMigrationRedirect

    const emailVerificationRedirect =
      !corporateRedirect && this.checkEmailVerification(ebookRedirect, euMigrateRedirect)
    if (emailVerificationRedirect) return emailVerificationRedirect

    const ebookDownloadRedirect = !corporateRedirect && this.checkDownloadEbook(ebookRedirect, euMigrateRedirect)
    if (ebookDownloadRedirect) return ebookDownloadRedirect

    const phoneVerificationRedirect =
      !corporateRedirect && this.checkPhoneVerification(ebookRedirect, euMigrateRedirect)
    if (phoneVerificationRedirect) return phoneVerificationRedirect

    const emailLoginVerificationRedirect =
      !corporateRedirect && this.checkEmailLoginVerification(ebookRedirect, euMigrateRedirect)
    if (emailLoginVerificationRedirect) return emailLoginVerificationRedirect

    if (!corporateRedirect && location.pathname.indexOf('/settings/profile/portfolio-management') === -1) {
      const checkClientNoticesRedirect = this.checkClientNoticesRedirect(ebookRedirect, euMigrateRedirect)
      if (checkClientNoticesRedirect) return checkClientNoticesRedirect

      const complianceRedirect = this.checkAppropTest(
        companyObject,
        verificationFields,
        ebookRedirect,
        euMigrateRedirect,
      )
      if (complianceRedirect) return complianceRedirect

      const ibDueDiligenceRedirect = this.checkDueDiligenceRedirect(ebookRedirect, euMigrateRedirect)
      if (ibDueDiligenceRedirect) return ibDueDiligenceRedirect

      const subscriptionRedirect = this.checkSubscriptionRedirect(ebookRedirect, euMigrateRedirect)
      if (subscriptionRedirect) return subscriptionRedirect
    }

    const showTemporaryCeasingPopup =
      !getItem('temporaryCeasingPopup') &&
      openTemporaryCeasingPopup &&
      !isEmpty(temporaryCeasingPopup) &&
      includes(temporaryCeasingPopup.countries, get(viewer, 'address.country')) &&
      includes(temporaryCeasingPopup.tradingStatuses, get(viewer, 'tradingStatus'))

    const checkPartnerZoneRedirect = this.checkParnerZone(clientType, ebookRedirect, euMigrateRedirect)
    if (checkPartnerZoneRedirect && get(location, 'pathname') === '/') return checkPartnerZoneRedirect

    return (
      <BugsnagErrorBoundary FallbackComponent={ErrorPage} beforeSend={report => this.beforeSendErrorReport(report)}>
        <AppContext.Provider
          value={{
            logout: this.handleLogout,
            showNotification: this.showNotification,
            company: get(viewer, 'whiteLabel') ? key : get(viewer, 'company') || key,
            locale,
            themePreference,
            toggleTheme: (theme, locale) => this.context.toggleTheme(theme, locale),
            tradingStatus,
            tradingStatusReasonCode,
            clientType,
            blockedDeposit,
            companyObject,
            hideNotification: () => this.setState({notification: null}),
            accounts,
            hasPartnerAccount,
            country: get(viewer, 'address.country'),
            clientId: get(viewer, 'id'),
            eligibleBonus: get(viewer, 'eligibleBonus.eligibleBonus'),
            acceptedTermsSpoaBonus: get(viewer, 'acceptedTermsSpoaBonus'),
            showSystemNotification: this.showSystemNotification,
            showRequestPartnerWalletPopup: this.showRequestPartnerWalletPopup,
            hideRequestPartnerWalletPopup: this.hideRequestPartnerWalletPopup,
          }}
        >
          {!isMobile() && (
            <Desktop viewer={viewer} notification={notification} onClose={() => this.setState({notification: null})} />
          )}
          {isMobile() && (
            <Mobile viewer={viewer} notification={notification} onClose={() => this.setState({notification: null})} />
          )}
        </AppContext.Provider>
        {systemNotificationMessage && systemNotificationType &&
        <SystemNotification
          type={systemNotificationType}
          message={systemNotificationMessage}
          customMessage={systemNotificationCustomMessage}
          hideSystemNotification = {() => this.hideSystemNotification()}
        />}
        {showAffiliateAgreementPopup && (
          <AffiliateAgreementPopup
            open={showAffiliateAgreementPopup}
            affiliateAggreementUrls={affiliateAggreementUrls}
            affiliateAggreementTemplate={affiliateAggreementTemplate}
          />
        )}
        {openPopup && (
          <AlertDialog
            open={openPopup}
            title={t(messages.importantNotice.i18nKey, messages.importantNotice.defaults)}
            disagreeText={t(messages.logout.i18nKey, messages.logout.defaults)}
            agreeText={t(messages.completeForm.i18nKey, messages.completeForm.defaults)}
            onDisagree={() => this.handleLogout()}
            onAgree={() => this.setState({openPopup: false})}
            onClose={() => this.setState({openPopup: false})}
          >
            <Typography variant="body1">
              {(get(location, 'pathname') !== '/settings/profile/due-diligence' && forceDocument) ?
                <Trans {...messages.pleaseCompletePopup} values={{forceDocument}} /> :
                <Trans {...messages.pleaseCompletePopupEdd} values={{forceDocument}} />}
            </Typography>
          </AlertDialog>
        )}
        {showTemporaryCeasingPopup && (
          <AlertDialog
            open={showTemporaryCeasingPopup}
            title={t(messages.importantNotice.i18nKey, messages.importantNotice.defaults)}
            onAgree={() => this.setState({openTemporaryCeasingPopup: false}, storeItem('temporaryCeasingPopup', true))}
            agreeText={t(messages.continue.i18nKey, messages.continue.defaults)}
          >
            <Typography variant="body1">
              <Trans
                {...messages.temporaryCeasingPopupText}
                values={{
                  countries: map(temporaryCeasingPopup.countries, country => find(countries, {key: country}).label),
                }}
              />
            </Typography>
            <br />
          </AlertDialog>
        )}
        {hasPartnerAccount && (
          <RequestPartnerAccountPopup
            open={openRequestPartnerWalletPopup}
            callbackRequested={openPartnerAccountRequested}
            onAgree={requestPartnerAccount}
            loading={requestPartnerAccountResult.loading}
            onClose={this.hideRequestPartnerWalletPopup}
          />
        )}
      </BugsnagErrorBoundary>
    )
  }
}

export default compose(
  withApollo,
  withNamespaces(),
  graphql(CLIENT_DATA_QUERY, {
    props: ({data: {loading, error}, data}) => ({
      loading,
      error,
      viewer: get(data, 'viewer'),
    }),
  }),
  graphql(ACCOUNTS_QUERY, {
    skip: props => !props.viewer,
    props: ({data: {loading: loadingAccounts, error: errorAccounts}, data}) => ({
      loadingAccounts,
      errorAccounts,
      accounts: get(data, 'viewer.accounts'),
    }),
  }),
  graphql(REQUEST_OPEN_PARTNER_ACCOUNT_MUTATION, {
    name: 'requestPartnerAccount',
    options: {
      ignoreResults: false,
      refetchQueries: [{query: CLIENT_DATA_QUERY}],
    },
    props: ({requestPartnerAccount, requestPartnerAccountResult}) => ({
      requestPartnerAccount,
      requestPartnerAccountResult,
    }),
  }),
)(App)
