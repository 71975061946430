import React, {Component} from 'react'
import {withApollo, graphql} from 'react-apollo'
import {withRouter} from 'react-router-dom'
import {flowRight as compose, get, filter, find, map, isNil, take, isEmpty} from 'lodash'
import {withNamespaces} from 'react-i18next'
import AppContext from '../Common/contexts/AppContext'
import withStyles from '@mui/styles/withStyles'
import {isBitnukAccount, isMobile, isPAMMFundManagerAccount, logEventCustomParams} from '../../common/utils'
import config from '../../config'
import ClientNotificationBar from '../Accounts/ClientNotificationBar'
import CustomNotificationAlert from '../Common/CustomNotificationAlert'
import {Box} from '@mui/material'
import {ACCOUNTS_QUERY, CLIENT_DATA_QUERY, TRANSACTIONS_CLIENT_QUERY} from '../../graphql/queries'
import PageBody from '../Common/PageBody'
import NotificationArea from './NotificationArea'
import ClientInfoSection from './ClientInfoSection'
import Footer from '../App/Footer'
import PromotionalBanners from '../Common/PromotionalBanners'
import TradingAccountsSection from './TradingAccountsSection'
import MarketAnalysis from '../Common/MarketAnalysis'
import RecentTransactionsSection from './RecentTransactionsSection'
import SocialMediaSection from '../Common/SocialMediaSection'
import LoadingWrapper from './LoadingWrapper'

const styles = theme => ({
  dashboardContainer: {
    display: 'flex',
    gap: theme.spacing(5),
    flexDirection: 'column',
  },
  carouselWrapper: {
    position: 'relative',
  }
})

class Dashboard extends Component {
  static contextType = AppContext

  constructor(props) {
    super(props)
    this.state = {
      showSensitiveData: true,
    }
  }

  async componentDidMount() {
    const {clientId, clientType} = this.context
    logEventCustomParams('homeIntent', {
      clientType,
      userId:  clientId,
    })
    const showSensitiveDataDefault = !isEmpty(window.localStorage.getItem('showSensitiveData'))
      ? JSON.parse(window.localStorage.getItem('showSensitiveData'))
      : this.state.showSensitiveData
    this.setState({showSensitiveData: showSensitiveDataDefault})
  }

  handleChangeParent(name, value) {
    this.setState({[name]: value})
  }

  render() {
    const {featuresConfig: {notificationArea}} = config
    const {blockedDeposit} = this.context
    const {accounts, loadingAccounts, loadingClient, loadingTransactions, viewer , classes, transactions}  = this.props
    const {showSensitiveData} = this.state
    const isMobileDevice = isMobile()
    const dataLoading = loadingAccounts || loadingClient || loadingTransactions

    const hasBitnuk = find(accounts, (a) => isBitnukAccount(accounts) && !accounts.balance)
    const showNotificationArea = get(notificationArea, 'enabled')

    const carouselAccounts = map(accounts, (a) => {
      const {__typename, accountSubtype, balance, currency, remoteId, isDemo,
        isReadOnly, isViewOnly, isArchived, id, ...rest} = a
      return {
        __typename, accountSubtype, currency, remoteId,
        isReadOnly, isViewOnly, isArchived, isDemo, id,
        balance: isNil(balance) ? 0 : balance,
        ...rest
      }
    })

    const transactionsData = map(take(transactions, 5), (t) => {
      const {amount, category, createdAt, currency, status, meta, transferAccount,
        account: {remoteId},
      } = t

      return {
        amount, currency, status, createdAt, remoteId, meta, transferAccount,
        type: category,
      }
    })

    return (
      <React.Fragment>
        <PageBody>
          <Box className={classes.dashboardContainer}>
            <Box>
              {showNotificationArea && <NotificationArea isMobileDevice={isMobileDevice} />}
            </Box>
            <Box>
              <ClientInfoSection loading={dataLoading} viewer={viewer} showSensitiveData={showSensitiveData} handleSensitiveData={this.handleChangeParent.bind(this)}/>
            </Box>
            {!blockedDeposit && <Box>
              <LoadingWrapper fullWidth loading={dataLoading}>
                {!blockedDeposit && <ClientNotificationBar accounts={accounts} hasBitnuk={hasBitnuk} />}
                <CustomNotificationAlert global/>
              </LoadingWrapper>
            </Box>}
            <Box className={classes.carouselWrapper}>
              <TradingAccountsSection
                loading={dataLoading}
                accounts={carouselAccounts}
                showSensitiveData={showSensitiveData}
                maxItemWidth={{lgUp: '365px', lgDown: '355px', mdDown: '375px'}}
              />
            </Box>
            <Box>
              <RecentTransactionsSection
                loading={dataLoading}
                transactions={transactionsData}
                showSensitiveData={showSensitiveData}
              />
            </Box>
            <Box>
              <MarketAnalysis
                maxItemWidth={{lgUp: '365px', lgDown: '355px', mdDown: '375px'}}
              />
            </Box>
            <Box>
              <PromotionalBanners
                maxItemWidth={{lgUp: '274px', mdDown: '280px'}}
              />
            </Box>
            <Box gap={3} display={'flex'} flexDirection={'column'}>
              <Box>
                <Footer noMarginTop viewer={viewer}/>
              </Box>
              <Box>
                <SocialMediaSection/>
              </Box>
            </Box>
          </Box>
        </PageBody>
      </React.Fragment>
    )
  }

}
export default compose(
  withApollo,
  withRouter,
  withNamespaces(),
  withStyles(styles, {withTheme: true}),
  graphql(ACCOUNTS_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => {
      const accounts = filter(get(data, 'viewer.accounts'), (account) => !isPAMMFundManagerAccount(account))
      return {
        error,
        accounts,
        loadingAccounts: loading,
      }
    }
  }),
  graphql(CLIENT_DATA_QUERY, {
    options: () => ({fetchPolicy: 'network-only'}),
    props: ({data: {error, loading}, data}) => {
      const country = get(data, 'viewer.address.country')
      return {
        errorClient: error,
        loadingClient: loading,
        country,
        viewer: get(data, 'viewer'),
      }
    }
  }),
  graphql(TRANSACTIONS_CLIENT_QUERY, {
    props: ({data: {error, loading}, data})  => ({
      error,
      loadingTransactions: loading,
      transactions: get(data, 'viewer.transactions'),
    })
  }),
)(Dashboard)
